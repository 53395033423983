/**Menu Mobile */
const menuBtn = document.querySelector('.menuMobile-btn');
const menuNav = document.querySelector('.menu-nav.mobile-menu');

const actionMenu = () => {
   menuBtn.addEventListener('click', () => {
      if (menuBtn.classList.contains('active')) {
         menuBtn.classList.remove('active');
         menuNav.classList.remove('active');
      } else {
         menuBtn.classList.add('active');
         menuNav.classList.add('active');
      }
   });
};
actionMenu();
