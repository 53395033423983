//Ativar menu fixo ao rolar scroll
function initHeader() {
   const windowTop = window.pageYOffset;
   const header = document.querySelector('header');

   if (windowTop === 0) {
      header.classList.remove('activeMenuTop');
   } else {
      header.classList.add('activeMenuTop');
   }
}
window.addEventListener('scroll', initHeader);

//Redirect Page ao clicar na logo
const logoMarca = document.querySelector('.logo');
logoMarca.addEventListener('click', () => {
   location.href = BASE;
});
